// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'
import { getAuth } from 'firebase/auth'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyAeV0zuvdlV22gz-qF9XrMWyS9UUu2qGZM',
  authDomain: 'nalendro.firebaseapp.com',
  projectId: 'nalendro',
  storageBucket: 'nalendro.appspot.com',
  messagingSenderId: '377307173334',
  appId: '1:377307173334:web:9cff313d669c6a40b35477',
}

// Initialize Firebase
initializeApp(firebaseConfig)

// initialize firestore
const db = getFirestore()

// initialize auth
const auth = getAuth()

// initialize storage()
const storage = getStorage()

export { db, auth, storage }
