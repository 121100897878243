import { useEffect, useState } from 'react'

import { db } from '../Firebase/Config'
import { collection, doc, onSnapshot } from 'firebase/firestore'

export default function useDocument(col, props) {
  const [document, setDocument] = useState(null)

  useEffect(() => {
    const ref = collection(db, col)

    const unsub = onSnapshot(doc(ref, props), (snapshot) => {
      setDocument(snapshot.data())
    })
    return () => unsub()
  }, [col, props])
  return { document }
}
