import { db, storage } from '../Firebase/Config'
import {
  addDoc,
  deleteDoc,
  doc,
  collection,
  Timestamp,
} from 'firebase/firestore'
import {
  getDownloadURL,
  ref,
  uploadBytes,
  deleteObject,
} from 'firebase/storage'

export function useFirestore(col) {
  const randomID = Math.floor(Math.random() * 130210)

  //   create project
  const createProject = async (
    title,
    thumbnail,
    description,
    link,
    assignedTechList
  ) => {
    // uploading files to storage
    const uploadPath = `thumbnails/${randomID}/${thumbnail.name}`
    const img = await ref(storage, uploadPath)
    await uploadBytes(img, thumbnail).then((snapshot) =>
      console.log('uploaded!')
    )
    // get data from file uploaded above
    const imgURL = await getDownloadURL(img)
    // upload data to firestore
    await addDoc(collection(db, col), {
      title,
      description,
      link,
      imgURL,
      assignedTechList,
    })
  }

  // create blog
  const createBlog = async (title, content, tags, picture) => {
    // uploading files to storage
    const uploadPath = `picture/${randomID}/${picture.name}`
    const img = await ref(storage, uploadPath)
    await uploadBytes(img, picture).then((snapshot) => console.log('uploaded!'))
    // get data from file uploaded above
    const imgURL = await getDownloadURL(img)
    //  add to firestore
    await addDoc(collection(db, col), {
      title,
      content,
      tags,
      imgURL,
      createdAt: Timestamp.fromDate(new Date()),
    })
  }

  // delete project
  const deleteProject = async (id, imgURL) => {
    // deleting firestore
    const docRef = doc(db, col, id)
    await deleteDoc(docRef)

    // deleting storage
    const imgRef = ref(storage, imgURL)
    deleteObject(imgRef)
  }

  return { createProject, createBlog, deleteProject }
}
