import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import style from './Create.module.css'

import { useRef, useState } from 'react'

import { useFirestore } from '../../Hooks/useFirestore'

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link', 'image'],
    ['clean'],
  ],
}

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
]

export default function CreateBlog() {
  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')
  const [tag, setTag] = useState('')
  const [picture, setPicture] = useState(null)
  const [pictureError, setPictureError] = useState(null)

  const [tags, setTags] = useState([])
  const tagInput = useRef()

  const { createBlog } = useFirestore('blog')

  const handleSubmit = async (e) => {
    e.preventDefault()

    createBlog(title, content, tags, picture)
    setTitle('')
    setContent('')
    setTags('')
  }
  // add picture for  thumbnail
  const handleFile = async (e) => {
    setPicture(null)

    const item = e.target.files[0]
    if (!item) {
      setPictureError('please select a file')
      return
    }
    if (!item.type.includes('image')) {
      setPictureError('please select image')
      return
    }
    if (item.size > 200000) {
      setPictureError('image must be less than 200kb')
      return
    }

    setPicture(item)
    setPictureError(null)
  }

  // add individual tag
  const handleAdd = (e) => {
    e.preventDefault()

    const tagg = tag.trim()

    if (tagg && !tags.includes(tagg)) {
      setTags((prevTags) => [...prevTags, tagg])
    }

    setTag('')
    tagInput.current.focus()
  }

  return (
    <div className={style.createblog}>
      <form onSubmit={handleSubmit}>
        <label>
          <span>Title</span>
          <input
            value={title}
            type='text'
            required
            onChange={(e) => setTitle(e.target.value)}
          />
        </label>
        <label>
          <span>Picture</span>
          <input type='file' onChange={handleFile} />
          {pictureError && <p>{pictureError}</p>}
        </label>
        <span>Paragraph</span>
        <ReactQuill
          theme='snow'
          placeholder={'Write something awesome...'}
          value={content}
          modules={modules}
          formats={formats}
          onChange={(e) => setContent(e)}
        />
        <div className={style.tagContainer}>
          <label>
            <span>Tag</span>
            <input
              type='text'
              value={tag}
              ref={tagInput}
              onChange={(e) => setTag(e.target.value)}
            />
          </label>
          <button onClick={handleAdd}>Add</button>
        </div>
        <p style={{ marginTop: '16px', marginBottom: '16px' }}>
          current Tags:
          {tags && tags.map((i) => <em key={i}>{i}, </em>)}
        </p>
        <button className='btn' style={{ borderRadius: '16px' }}>
          Upload
        </button>
      </form>
    </div>
  )
}
