import { useState, useEffect } from 'react'
import style from './Create.module.css'
import Select from 'react-select'

import { useFirestore } from '../../Hooks/useFirestore'
import { useCollection } from '../../Hooks/useCollection'

export default function CreateProject() {
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [link, setLink] = useState('')
  const [thumbnail, setThumbnail] = useState(null)
  const [errorForm, setErrorForm] = useState('')

  const [thumbnailError, setThumbnailError] = useState(null)

  const { documents } = useCollection('image')
  const { createProject } = useFirestore('projects')

  const [tech, setTech] = useState([])
  const [assignedTech, setAssignedTech] = useState([])

  useEffect(() => {
    if (documents) {
      const imgItem = documents.map((item) => {
        // console.log(item)
        return {
          value: { ...item, id: item.id },
          label: item.title,
        }
      })
      setTech(imgItem)
    }
  }, [documents])

  const handleSubmit = (e) => {
    e.preventDefault()
    if (assignedTech.length < 1) {
      setErrorForm('Please select atleast 1 tech')
    }

    const assignedTechList = assignedTech.map((img) => {
      return {
        title: img.value.title,
        imgURL: img.value.imgURL,
        id: img.value.id,
      }
    })
    // console.log(title, thumbnail, description, link, assignedTechList)
    createProject(title, thumbnail, description, link, assignedTechList)
  }

  const handleFileChange = async (e) => {
    setThumbnail(null)

    const item = e.target.files[0]
    if (!item) {
      setThumbnailError('please select a file')
      return
    }
    if (!item.type.includes('image')) {
      setThumbnailError('please select image')
      return
    }
    if (item.size > 200000) {
      setThumbnailError('image must be less than 200kb')
      return
    }
    setThumbnail(item)
    setThumbnailError(null)
  }

  return (
    <div className={style.createproject}>
      <form onSubmit={handleSubmit}>
        <label>
          <span>Thumbnail</span>
          <input type='file' onChange={handleFileChange} />
        </label>
        <label>
          <span>Title</span>
          <input
            type='text'
            onChange={(e) => {
              setTitle(e.target.value)
            }}
          />
        </label>
        <label>
          <span>Link</span>
          <input
            type='text'
            onChange={(e) => {
              setLink(e.target.value)
            }}
          />
        </label>
        <label>
          <span>Description</span>
          <textarea
            name='project-description'
            cols='20'
            rows='10'
            onChange={(e) => {
              setDescription(e.target.value)
            }}
          ></textarea>
        </label>
        {thumbnailError && <p>{thumbnailError}</p>}
        <label>
          <span>Tech Logo:</span>
          <Select
            styles={{ width: '200' }}
            className='harus-pendek'
            options={tech}
            onChange={(img) => setAssignedTech(img)}
            isMulti
          />
          {errorForm && <p>{errorForm}</p>}
        </label>
        <button className='btn'>Upload</button>
      </form>
    </div>
  )
}
