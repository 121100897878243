import { useState } from 'react'
import style from './Create.module.css'
import CreateBlog from './CreateBlog'
import CreateProject from './CreateProject'
import CreateImg from './CreateImg'

export default function Create() {
  const [creating, setCreating] = useState('project')

  const colectSelection = (e) => {
    const select = e.target.value
    setCreating(select)
  }

  return (
    <div className={style.create}>
      <div className={style.createtitle}>
        <h2>Create</h2>
        <select
          name="blogproject"
          className={style.blogproject}
          onChange={colectSelection}
        >
          <option value="project">Project</option>
          <option value="blog">Blog</option>
          <option value="img">Img</option>
        </select>
      </div>
      <div className="container">
        {creating === 'blog' && <CreateBlog />}
        {creating === 'project' && <CreateProject />}
        {creating === 'img' && <CreateImg />}
      </div>
    </div>
  )
}
