import BlogCard from '../../components/BlogCard'
import './Blog.css'
import { useCollection } from '../../Hooks/useCollection'

export default function Blog() {
  const { documents: blog } = useCollection('blog')

  return (
    <div className="blog">
      <h2>Thoughts</h2>
      <div className="container">
        {blog &&
          blog.map((data) => (
            <BlogCard
              pic={data.imgURL}
              title={data.title}
              content={data.content}
              key={data.id}
              tag={data.tags}
              id={data.id}
              date={data.createdAt.toDate()}
            />
          ))}
      </div>
    </div>
  )
}
