import { useState } from 'react'
import { NavLink } from 'react-router-dom'

import { useLogout } from '../Hooks/useLogout'
import { useAuthContext } from '../Hooks/useAuthContext'

import './Navbar.css'

export default function Navbar() {
  const [active, setActive] = useState('')
  const { logout } = useLogout()

  const { user } = useAuthContext()

  // const loc = useLocation().pathname
  // console.log(loc)

  return (
    <div className="navbar">
      <ul>
        <li>
          <NavLink
            to="/"
            onClick={() => {
              setActive('')
            }}
          >
            Nalendro
          </NavLink>
        </li>
        <li>
          <NavLink to="projects">Experiment</NavLink>
          <NavLink to="blog">Thoughts</NavLink>
          <NavLink to="contact">Contact</NavLink>
          {user && <NavLink to="create">Create</NavLink>}
          {user && <button onClick={logout}>Logout</button>}
        </li>
        <li
          className={`nav-icon1 ${active}`}
          onClick={() => {
            setActive(!active ? 'active' : '')
          }}
        >
          <span></span>
          <span></span>
          <span></span>
        </li>
      </ul>
      <div className={`hidden-menu ${active}`}>
        <li>
          <NavLink
            to="projects"
            onClick={() => {
              setActive('')
            }}
          >
            Experiment
          </NavLink>
          <NavLink
            to="blog"
            onClick={() => {
              setActive('')
            }}
          >
            Thoughts
          </NavLink>

          <NavLink
            to="contact"
            onClick={() => {
              setActive('')
            }}
          >
            Contact
          </NavLink>
        </li>
      </div>
    </div>
  )
}
