import { useState, useEffect } from 'react'

import { db } from '../Firebase/Config'
import { collection, onSnapshot } from 'firebase/firestore'

export const useCollection = (col) => {
  const [documents, setDocuments] = useState(null)

  useEffect(() => {
    let ref = collection(db, col)

    const unsub = onSnapshot(ref, (snapshot) => {
      let result = []
      snapshot.docs.forEach((doc) => {
        result.push({ ...doc.data(), id: doc.id })
      })
      setDocuments(result)
    })
    return () => unsub()
  }, [col])
  return { documents }
}
