import { useParams } from 'react-router-dom'
import useDocument from '../../Hooks/useDocument'
import './ProjectDetail.css'

export default function ProjectDetail() {
  const { id } = useParams()

  const { document } = useDocument('projects', id)

  return (
    <div className="project-details">
      {document && <h2>{document.title}</h2>}
      <div className="container">
        {document && <img src={document.imgURL} alt="my project" />}
        <div className="detailed">
          <h3>Project Description</h3>
          <br />
          {document && <p>{document.description}</p>}
          <div className="detailed-detail">
            <div className="more-detail">
              <h4>Technology</h4>
              {document &&
                document.assignedTechList.map((item) => (
                  <img src={item.imgURL} alt="tech logo" key={item.title} />
                ))}
            </div>
            {document && (
              <a
                href={document.link}
                target="_blank"
                rel="noreferrer"
                aria-label="Github"
              >
                Live Preview
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
