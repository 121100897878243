import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuthContext } from '../../Hooks/useAuthContext'
import useLogin from '../../Hooks/useLogin'

import './Login.css'

export default function Login() {
  const [user, setUser] = useState('')
  const [password, setPassword] = useState('')
  const { login, error } = useLogin()
  let navigate = useNavigate()

  const { user: u } = useAuthContext()

  const handleSubmit = (e) => {
    e.preventDefault()
    login(user, password)
    setUser('')
    setPassword('')
  }

  useEffect(() => {
    async function check() {
      if (u) {
        await navigate('/create')
      }
    }
    check()
  }, [navigate, u])

  return (
    <div className="login">
      <h2>Login</h2>
      <form onSubmit={handleSubmit}>
        <label>
          <span>Email :</span>
          <input
            type="email"
            value={user}
            onChange={(e) => {
              setUser(e.target.value)
            }}
          />
        </label>
        <label>
          <span>Password :</span>
          <input
            value={password}
            type="password"
            onChange={(e) => {
              setPassword(e.target.value)
            }}
          />
        </label>
        <button className="btn">Login</button>
        {error && <p>{error}</p>}
      </form>
    </div>
  )
}
