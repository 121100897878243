import { BrowserRouter, Route, Routes } from 'react-router-dom'

import './App.css'
import Contact from './pages/contact/Contact'
import Home from './pages/home/Home'
import Projects from './pages/project/Project'
import BlogDetail from './pages/blogdetail/BlogDetail'
import Create from './pages/create/Create'
import Blog from './pages/blog/Blog'
import Login from './pages/login/Login'
import ProjectDetail from './pages/projectdetail/ProjectDetail'
import CreateBlog from './pages/create/CreateBlog'
import CreateProject from './pages/create/CreateProject'
import ProtectedRoute from './components/ProtectedRoute'
import { useAuthContext } from './Hooks/useAuthContext'
import WithNav from './components/WithNav'
import WithoutNav from './components/WithoutNav'

function App() {
  const { authIsReady } = useAuthContext()

  return (
    <div className='App'>
      {authIsReady && (
        <BrowserRouter>
          <Routes>
            <Route element={<WithoutNav />}>
              <Route path='/l0g1n' element={<Login />} />
            </Route>
            <Route element={<WithNav />}>
              <Route exact path='/' element={<Home />} />
              <Route path='/contact' element={<Contact />} />
              <Route path='/projects' element={<Projects />} />
              <Route path='/blog' element={<Blog />} />
              <Route path='/blogdetail/:id' element={<BlogDetail />} />
              <Route path='/projectdetail/:id' element={<ProjectDetail />} />
              <Route
                path='/create'
                element={
                  <ProtectedRoute pars={'wello'}>
                    <Create />
                  </ProtectedRoute>
                }
              />
              <Route path='/create/createblog' element={<CreateBlog />} />
              <Route path='/create/createproject' element={<CreateProject />} />
            </Route>
          </Routes>
        </BrowserRouter>
      )}
    </div>
  )
}

export default App
