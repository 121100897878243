import githubIcon from '../../Assets/iconmonstr-github-2.svg'
import linkedinIcon from '../../Assets/iconmonstr-linkedin-2.svg'
import './Contact.css'

export default function Contact() {
  return (
    <div className="contact">
      <h2>Contact</h2>
      <div className="container">
        <div className="kiri">
          <p>
            If you’d like to chat about a project then please contact me on the
            linkedin. because this form still developed.
          </p>
          <form>
            <label>
              <span>Email:</span>
              <input type="email" />
            </label>
            <label>
              <span>Your Message</span>
              <textarea></textarea>
            </label>
            <button className="btn">Submit</button>
          </form>
        </div>
        <div className="kanan">
          <h3>You can find me on</h3>
          <a
            href="https://github.com/nalendro16"
            target="_blank"
            rel="noreferrer"
            aria-label="Github"
          >
            <img src={githubIcon} alt="linkedin logo" />
          </a>
          <a
            href="https://www.linkedin.com/in/nalendro-agung-wicaksono-221a54174/"
            target="_blank"
            rel="noreferrer"
            aria-label="Github"
          >
            <img src={linkedinIcon} alt="github logo" />
          </a>
        </div>
      </div>
    </div>
  )
}
