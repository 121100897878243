import ProjectCard from '../../components/ProjectCard'
import './Project.css'
import { useCollection } from '../../Hooks/useCollection'

export default function Project() {
  const { documents: projects } = useCollection('projects')

  return (
    <div className="project">
      <h2>Experiment</h2>
      <div className="container">
        {projects &&
          projects.map((project) => (
            <ProjectCard
              id={project.id}
              key={project.id}
              title={project.title}
              img={project.imgURL}
              desc={project.description}
            />
          ))}
      </div>
    </div>
  )
}
