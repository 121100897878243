import { Link } from 'react-router-dom'
import { useFirestore } from '../Hooks/useFirestore'
import { useAuthContext } from '../Hooks/useAuthContext'
import formatDistanceToNow from 'date-fns/esm/formatDistanceToNow'
import './BlogCard.css'

export default function BlogCard({ title, date, tag, id, pic }) {
  const { deleteProject } = useFirestore('blog')
  const { user } = useAuthContext()
  const handleClick = (id) => {
    deleteProject(id, pic)
  }
  return (
    <div className="blog-card">
      <div className="picture-container">
        <img src={pic} alt="thumbnail for blog" />
      </div>
      <div className="content">
        <div className="createdAt">
          <p>{formatDistanceToNow(date, { addSuffix: true })}</p>
          <Link to={`/blogdetail/${id}`}>
            <h3>{title}</h3>
          </Link>
        </div>
        <div className="blog-tag">
          {tag.map((tag) => (
            <p key={tag}>#{tag}</p>
          ))}
        </div>
      </div>
      {user && (
        <button className="delete-card" onClick={() => handleClick(id)}>
          X
        </button>
      )}
    </div>
  )
}
