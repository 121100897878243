import { useState } from 'react'

import { db, storage } from '../../Firebase/Config'
import { addDoc, collection } from 'firebase/firestore'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'

import style from './Create.module.css'

export default function CreateImg() {
  const [title, setTitle] = useState('')
  const [thumbnail, setThumbnail] = useState(null)

  const [thumbnailError, setThumbnailError] = useState(null)

  const handleUpload = async (e) => {
    e.preventDefault()
    const uploadPath = `icon/${thumbnail.name}`
    const img = await ref(storage, uploadPath)
    await uploadBytes(img, thumbnail).then((snapshot) =>
      console.log('uploaded!')
    )
    // get data from file uploaded above
    const imgURL = await getDownloadURL(img)
    // upload data to firestore
    await addDoc(collection(db, 'image'), {
      title,
      imgURL,
    })
  }

  const handleFile = async (e) => {
    setThumbnail(null)

    const item = e.target.files[0]
    if (!item) {
      setThumbnailError('please select a file')
      return
    }
    if (!item.type.includes('image')) {
      setThumbnailError('please select image')
      return
    }
    if (item.size > 150000) {
      setThumbnailError('image must be less than 200kb')
      return
    }
    setThumbnail(item)
    setThumbnailError(null)
  }

  return (
    <div className={style.createimg}>
      <form onSubmit={handleUpload}>
        <label>
          <span>Title</span>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </label>
        <label>
          <span>Upload Logo</span>
          <input type="file" onChange={handleFile} />
        </label>
        {thumbnailError && <p>{thumbnailError}</p>}
        <button className="btn">Upload Logo</button>
      </form>
    </div>
  )
}
