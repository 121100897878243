import { useParams } from 'react-router-dom'
import useDocument from '../../Hooks/useDocument'
import formatDistanceToNow from 'date-fns/formatDistanceToNow/index.js'
import './Blogdetail.css'

export default function BlogDetail() {
  const { id } = useParams()

  const { document } = useDocument('blog', id)

  return (
    <div>
      {document && (
        <div className="blog-detail">
          <h2>{document.title}</h2>
          <p>
            {formatDistanceToNow(document.createdAt.toDate(), {
              addSuffix: true,
            })}
          </p>
          <div
            className="content-detail"
            dangerouslySetInnerHTML={{ __html: document.content }}
          ></div>
        </div>
      )}
    </div>
  )
}
