import { Link } from 'react-router-dom'
import { useState } from 'react'
import { useCollection } from '../../Hooks/useCollection'
import img from '../../Assets/Desktop.png'
import './Home.css'

export default function Home() {
  const [show, setShow] = useState('show')
  const { documents } = useCollection('image')

  // console.log(show)
  return (
    <div className='home'>
      <div className='home-container'>
        <div className={show === 'show' ? 'kiri' : 'kiri-hidden'}>
          <p
            style={{
              fontSize: '24px',
              color: 'black',
              fontWeight: '800',
              marginTop: '12px',
            }}
          >
            Hi, i am Nalendro Agung W
          </p>
          <p style={{ fontSize: '12px', marginTop: '8px' }}>
            I'm building User Interfaces.
            <br />I wrote it on React, styling it with Tailwind Css, and if
            needed compiled it with Capacitor JS or Ionic
          </p>
          <div className='btn-div'>
            <button style={{ fontSize: '12px' }}>
              <Link to='/projects'>Experiment</Link>
            </button>
            <button style={{ fontSize: '12px' }}>
              <Link to='/blog'>Thoughts</Link>
            </button>
            <button
              style={{ fontSize: '12px', padding: '8px' }}
              onClick={() => setShow(show === 'show' ? 'notShow' : 'show')}
            >
              About
            </button>
          </div>
        </div>
        <div className='tengah'>
          <img src={img} alt='transparent background' />
        </div>
        <div className={show === 'notShow' ? 'kanan' : 'kanan-hidden'}>
          <h4>About Me</h4>
          <br />
          <p>
            Hello! My name is Agung currently i work as Front End
            Developer(React JS) in Technopartner Indonesia.
          </p>
          <p>
            My web development journey started back in 2015
            <br />I start learn HTML, CSS, Javascript, and PHP in Vocational
            Highschool, but it goes up and down. And in 2019 after get my
            associate degree in electrical engineering, i back to relearn
            Javascript.
          </p>
          <h3>I'm Familiar With:</h3>
          <div className='content-foto'>
            {documents &&
              documents.map((logo) => (
                <img src={logo.imgURL} alt='stack logo' key={logo.id} />
              ))}
          </div>
          <button onClick={() => setShow(show === 'show' ? 'notShow' : 'show')}>
            Back
          </button>
        </div>
      </div>
    </div>
  )
}
