import { useFirestore } from '../Hooks/useFirestore'
import { useAuthContext } from '../Hooks/useAuthContext'
import { Link } from 'react-router-dom'
import './ProjectCard.css'

export default function ProjectCard({ title, img, desc, id }) {
  const { user } = useAuthContext()
  const { deleteProject } = useFirestore('projects')

  const handleClick = (id) => {
    deleteProject(id, img)
  }
  return (
    <div className='project-card'>
      <div className='img-container'>
        <img src={img} alt='My personal project' />
      </div>
      <div className='content-card'>
        <Link to={`/projectdetail/${id}`}>
          <h3>{title}</h3>
        </Link>
        {user && (
          <button className='delete-card' onClick={() => handleClick(id)}>
            X
          </button>
        )}
      </div>
    </div>
  )
}
